import { Card } from '../../../../../component';
import { Box, LoadingOverlay, Space, Text } from '@mantine/core';
import { ProgressDisplay } from './ProgressDisplay';
import { useAudioPlayer } from './useAudioPlayer';
import { AudioControls } from './AudioControls';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import apiUrl from '../../../../../config/apiUrl';
import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import {
  usePodcastFeedbackStore,
  usePodcastStore,
} from '../../../../../store/podcast';
import { FeedbackModal } from '../feedback/FeedbackModal';
import {
  ObtainablePoints,
  ObtainablePointsStatus,
} from '../../../../../component/ObtainablePoints';

export const PlayerCard = observer(
  ({ title, description }: { title: string; description: string }) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const { podcastId } = useParams();
    const podcastStore = usePodcastStore();
    const podcastFeedbackStore = usePodcastFeedbackStore();
    const {
      paused,
      toggleAudio,
      forward,
      replay,
      currentTime,
      updateCurrentTime,
      duration,
      finishedLoading,
      setPlaybackRate,
      setAudioRef,
    } = useAudioPlayer(() => {
      podcastStore.markPodcastAsFinished(Number(podcastId), () => {
        podcastFeedbackStore.modalOpen = true;
      });
    });
    const { i18n } = useTranslation();

    useEffect(() => {
      if (audioRef.current) setAudioRef(audioRef.current);
    }, [setAudioRef]);

    useEffect(() => {
      if (!podcastId || isNaN(Number(podcastId))) return;
    }, [podcastId]);

    return (
      <Box pos="relative">
        <FeedbackModal podcastId={Number(podcastId)} />
        <LoadingOverlay
          visible={!finishedLoading}
          zIndex={1000}
          overlayProps={{ blur: 1 }}
          loaderProps={{ type: 'bars' }}
        />
        <Card style={{ padding: 24 }}>
          <ObtainablePoints
            points={100}
            status={ObtainablePointsStatus.Available}
          />
          <Text fw="600" size="21px" lh="24px">
            {title}
          </Text>
          <Text
            size="16px"
            lh="24px"
            c="#202020
        "
          >
            {description}
          </Text>

          <audio ref={audioRef} preload="true">
            <source
              // this url can be used for testing
              // src="https://sample-videos.com/audio/mp3/crowd-cheering.mp3"
              src={`${apiUrl}api/potcast/${podcastId}/stream/${i18n.language}`}
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>

          <Space h={32} />

          <ProgressDisplay
            currentTime={currentTime}
            updateCurrentTime={updateCurrentTime}
            duration={duration}
          />
          <AudioControls
            toggleAudio={toggleAudio}
            forward={forward}
            replay={replay}
            currentTime={currentTime}
            duration={duration}
            paused={paused}
            setPlaybackRate={setPlaybackRate}
          />
        </Card>
      </Box>
    );
  },
);
