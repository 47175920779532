import { useTranslation } from 'react-i18next';
import { THCLimitBanner } from '../../../../../../component';
import { useUserMeStore } from '../../../../../../store';
import { useEffect } from 'react';
import { CohortType } from '@prisma/client';
import { useAvailableThcAmount } from '../../../../../../hooks';
import { defaultTHCLimitInGrams } from '@web/common';

export const ThcLimitWarning = () => {
  const { t } = useTranslation('profilescreen');
  const userMeStore = useUserMeStore();
  const { availableAmount, thcLimit, currentThcUnit } = useAvailableThcAmount();

  useEffect(() => {
    userMeStore.loadMyData();
  }, [userMeStore]);

  const canSeeLimitWarning =
    userMeStore.myInfo?.Participant?.cohortType === CohortType.LIMITED_ACCESS;

  // the user should only see this warning if he has LIMITED_ACCESS & the current limit is below 10g
  // eg. if he used 0g / 7g (7g is own set limit below 10g) he should still see the warning!
  if (!canSeeLimitWarning || thcLimit === defaultTHCLimitInGrams) return null;

  return (
    <THCLimitBanner
      padding="10px 0px 0px 0px"
      maxWidth="100%"
      margin="0px"
      title={t('ThcLimitWarning.Title')}
      limit={availableAmount + currentThcUnit}
      description={t('ThcLimitWarning.Description', {
        limit: thcLimit,
        unit: currentThcUnit,
      })}
    />
  );
};
