import { Button, Flex, Group, Text, TextInput } from '@mantine/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MyIcon, Reason } from '../../../../../../component';
import { useState } from 'react';
import { useDiaryDialogStore } from '../DiaryDialogStore';

export const Reasons = observer(({ makeStep }: { makeStep: () => void }) => {
  const { t } = useTranslation('diary-dialog');
  const store = useDiaryDialogStore();
  const didConsume = store.didConsume;
  const [showTextInput, setShowTextInput] = useState(false);
  const [newReason, setNewReason] = useState('');
  const [reasons, setReasons] = useState<string[]>(
    t('Reasons.List', { returnObjects: true }),
  );

  function addReason() {
    if (newReason.length > 0) {
      setReasons([...reasons, newReason]);
      setNewReason('');
      setShowTextInput(false);
    }
  }

  return (
    <Flex direction="column" gap="10px">
      <Text fw="600" size="16px" lh="24px">
        {t(
          'Reasons.' +
            (didConsume ? 'WhyDidYouConsume' : 'WhyDidYouNotConsume'),
        )}
      </Text>
      <Text c="#4A4A48"> {t('Reasons.SelectYourReason')}</Text>
      {reasons.map((reason) => (
        <Reason
          key={reason}
          reason={reason}
          onChange={() => store.addConsumptionReason(reason)}
        />
      ))}
      {showTextInput && (
        <Flex align="flex-end" justify="space-between" gap="10px">
          <TextInput
            flex={1}
            label={t('Reasons.NewConsumptionReason')}
            placeholder={t('Reasons.EnterReason')}
            value={newReason}
            onChange={(event) => setNewReason(event.currentTarget.value)}
          />
          <Button p="5px" onClick={addReason} disabled={!newReason}>
            <MyIcon name="add" style={{ fill: '#fff' }} />
          </Button>
        </Flex>
      )}

      <Button
        variant="transparent"
        fullWidth
        justify="space-between"
        rightSection={<MyIcon name="add" style={{ fill: '#3D7F53' }} />}
        p="0px"
        onClick={() => setShowTextInput(true)}
      >
        {t('Reasons.AddNewReason')}
      </Button>
      <Group justify="center" mt="xl">
        <Button onClick={makeStep} w="100%">
          {t('Next')}
        </Button>
      </Group>
    </Flex>
  );
});
